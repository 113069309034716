import type { ConversionEvent } from "@/components/Tracking/Kilkaya/kilkaya.types";
import {
  archivePageLink,
  latestPageLink,
} from "@/components/page-links.constants";
import { useSite } from "@/contexts/site/site.context";
import { useTrackingData } from "@/contexts/tracking/tracking.context";
import { useUser } from "@/contexts/user/hooks/use-user.hook";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import { useTrackingVersion } from "@/hooks/tracking/use-tracking-version";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

/**
 * More information and properties can be found in the Kilkaya documentation:
 * https://docs.google.com/spreadsheets/d/1AtAjcZt9pznS0bEHGtu2RpK8Q5IzCY6A_0rZMtExLnA/edit?gid=0#gid=0
 */
type KilkayaClickEvent = {
  readonly url: string;
  readonly toUrl: string;
  readonly position?: string;
};

function trackClick(eventName: KilkayaClickEvent) {
  if (typeof kilkaya === "undefined") {
    return;
  }

  kilkaya.click(eventName);
}

export function useKilkayaClickEvent() {
  return { trackClick };
}

export function useKilkayaConversion(conversionEvent: ConversionEvent | null) {
  const consent = useCookiebotConsent();

  useEffect(() => {
    if (
      consent.submitted &&
      conversionEvent !== null &&
      typeof kilkaya !== "undefined"
    ) {
      kilkaya.pageview({
        cntTag: conversionEvent,
        conversion: 1,
        nopv: 1,
      });
    }
  }, [consent, conversionEvent]);
}

export function useKilkayaPageView(
  referrer: null | string,
  currentUrl: string,
) {
  const { model: user } = useUser();
  const { trackingData } = useTrackingData();
  const consent = useCookiebotConsent();
  const pathname = usePathname();
  const { domain } = useSite();
  const searchParams = useSearchParams().toString();
  const trackingVersion = useTrackingVersion();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (trackingVersion !== "v1") return;

    if (trackingData === null || !consent.submitted) {
      return;
    }

    const {
      contentAuthor,
      contentModifiedTime,
      contentPublishTime,
      conversion,
      description,
      imageUrlPath,
      pageRestrictedType,
      pageType,
      referrer: referer,
      sectionName,
      title,
    } = trackingData;

    window.k5aMeta = {
      author: contentAuthor?.map((author) => author) ?? null,
      cntTag: null,
      consent: consent.marketing ? 1 : 0,
      contentTag: null,
      conversion: conversion ? 1 : 0,
      description,
      image: imageUrlPath,
      login: user.loggedIn ? 1 : 0,
      modifiedtime: contentModifiedTime,
      paid: pageRestrictedType === "paid" ? 1 : 0,
      paywall: getPaywallType(pageRestrictedType, user.hasAccess),
      publishtime: contentPublishTime,
      referrer: referrer ?? referer,
      section: sectionName,
      subscriber: user.hasAccess ? 1 : 0,
      subsection: null,
      tag: null,
      title,
      type: getPageType(pageType, currentUrl),
      url: `https://${domain}${pathname}${searchParams ? searchParams.toString() : ""}`,
    };

    document.dispatchEvent(new Event("K5A:SpaReady"));
  }, [consent, trackingData, domain, pathname, searchParams, trackingVersion]);
}

function getPaywallType(pageRestrictedType: string, hasAccess: boolean) {
  switch (pageRestrictedType) {
    case "free":
      return "open";
    case "paid":
      return hasAccess ? "open" : "hard";
    default:
      return "open";
  }
}

function getPageType(pageType: string, currentUrl: string) {
  const path = new URL(decodeURIComponent(currentUrl)).pathname;
  switch (path) {
    case "/":
      return "frontpage";
    case archivePageLink:
      return "archive";
    case latestPageLink:
      return "latest";
    default:
      switch (pageType) {
        case "art":
          return "article";
        case "sec":
          return "section";
        default:
          return "website";
      }
  }
}
